import React from "react";
import { ButtonTypes } from "../../utils/Constants";

const getButtonClasses = (type: ButtonTypes): string => {
  switch (type) {
    case ButtonTypes.PRIMARY:
      return "bg-blue-primary text-white hover:bg-blue-secondary disabled:bg-blue-71 py-2.5 px-8";
    case ButtonTypes.SECONDARY:
      return "bg-[#C9C9C9] text-blue-primary border border-solid hover:bg-gray-e8 py-2.5 px-8";
    case ButtonTypes.CANCEL:
      return "bg-white text-black41 hover:bg-gray-f5 py-2.5 px-8";
    case ButtonTypes.ERROR:
      return "bg-red-primary text-white hover:bg-red-secondary py-2.5 px-8";
    case ButtonTypes.RESET:
      return "bg-white text-blue-primary hover:font-semibold";
    default:
      return "";
  }
};

const Button: React.FC<{
  type: ButtonTypes;
  onClick?: () => void;
  className?: string;
  disabled?: boolean;
  dataAction?: string;
  children?: React.ReactNode;
}> = ({ type, onClick, className, disabled, dataAction, children }) => {

  const buttonClasses = `first-letter:capitalize text-sm rounded duration-75 ${getButtonClasses(
    type
  )} ${className}`;

  return (
    <button
      data-action={dataAction}
      className={buttonClasses}
      onClick={() => {
        if (!disabled) onClick?.();
      }}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default Button;
