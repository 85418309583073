import { faCloudUpload } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { ValidationState } from "../../utils/Constants";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

interface FileInputProps {
  onFileChange: (e: any) => void;
  file: File | undefined;
  label?: string;
  mandatory?: boolean;
  className?: string;
  customText?: string;
  hasError: ValidationState | boolean;
  errorMessage: string;
  accept?: string;
  showFileName?: boolean;
}

const FileInput: React.FC<FileInputProps> = ({
  onFileChange,
  file,
  label,
  mandatory,
  className,
  customText,
  hasError,
  errorMessage,
  accept,
  showFileName,
}) => {
  const applyErrorStyle =
    typeof hasError === "boolean"
      ? hasError
      : hasError === ValidationState.INVALID;

  return (
    <span>
      {label && (
        <label className="block text-gray-41 mb-[6px] text-sm">
          {label} {mandatory && <span className="text-red-500 ml-1">*</span>}
        </label>
      )}
      <div
        className={`${className} ${applyErrorStyle &&
          "placeholder:text-red-primary border-red-primary text-red-primary"
          } flex w-full p-2 pl-4 h-[70px] text-sm text-black41 rounded border border-gray-df bg-gray-f6 placeholder:text-gray-d7 hover:bg-white hover:border-dashed hover:border-blue-primary focus:outline-none focus:bg-white focus:border-blue-71 focus:caret-blue-71 relative overflow-hidden`}
      >
        <input
          type="file"
          className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
          onChange={onFileChange}
          accept={accept}
        />
        {applyErrorStyle && (
          <FontAwesomeIcon
            className="absolute top-1/2 -translate-y-1/2 right-4 text-red-primary text-base"
            icon={faExclamationCircle}
          />
        )}
        {file && showFileName ? (
          <span className="m-auto"> {file.name}</span>
        ) : customText ? (
          <span className="m-auto">{customText}</span>
        ) : (
          <span
            className={`m-auto text-gray-d7 ${applyErrorStyle && "text-red-primary"
              }`}
          >
            <FontAwesomeIcon
              icon={faCloudUpload}
              className={`mr-2 text-blue-primary ${applyErrorStyle && "text-red-primary"
                }`}
              size="lg"
            />
            <span
              className={` text-blue-primary ${applyErrorStyle && "text-red-primary"
                }`}
            >
              Browse
            </span>{" "}
            or drag a file here
          </span>
        )}
      </div>
      {applyErrorStyle && errorMessage && (
        <p className="text-xs text-red-primary first-letter:uppercase">
          {errorMessage}
        </p>
      )}
    </span>
  );
};

export default FileInput;
