import { useTranslation } from "react-i18next";
import { StatusBadgeTypes } from "../../utils/Constants";

const statusBadgeStyles: Record<StatusBadgeTypes, { color: string; bgColor: string }> = {
  [StatusBadgeTypes.SUBSCRIBED]: { color: 'text-green-primary', bgColor: 'bg-green-highlight' },
  [StatusBadgeTypes.UNSUBSCRIBED]: { color: 'text-red-primary', bgColor: 'bg-red-highlight' },
  [StatusBadgeTypes.RESPONDED]: { color: 'text-green-primary', bgColor: 'bg-green-highlight' },
  [StatusBadgeTypes.PENDING]: { color: 'text-orange-primary', bgColor: 'bg-orange-highlight' },
  [StatusBadgeTypes.PUBLISHED]: { color: 'text-green-primary', bgColor: 'bg-green-highlight' },
  [StatusBadgeTypes.DRAFT]: { color: 'text-orange-primary', bgColor: 'bg-orange-highlight' }
};

const StatusBadge: React.FC<{ type: StatusBadgeTypes }> = ({ type }) => {
  const { t } = useTranslation();
  const { color, bgColor } = statusBadgeStyles[type];

  return (
    <p className={`p-2 rounded capitalize inline ${color} ${bgColor}`}>
      {t(type)}
    </p>
  );
};

export default StatusBadge;
