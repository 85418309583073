import React, { KeyboardEventHandler, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { ValidationState } from "../../utils/Constants";
import { useTranslation } from "react-i18next";
import EN from "../../images/english.svg";
import FR from "../../images/french.svg";

interface TextInputProps {
  value: string | undefined;
  placeholder: string;
  className?: string;
  dir?: string;
  inputClassName?: string;
  hasError: ValidationState | boolean;
  errorMessage: string;
  onTextInputChanged: (value: string) => void;
  onKeyPress?: KeyboardEventHandler<HTMLInputElement>;
  label?: string;
  mandatory?: boolean;
  languageFlag?: string;
}

const TextInput: React.FC<TextInputProps> = ({
  value,
  placeholder,
  className,
  dir,
  inputClassName,
  hasError,
  errorMessage,
  onTextInputChanged,
  onKeyPress,
  label,
  mandatory,
  languageFlag,
}) => {
  const { t } = useTranslation();
  const [textInputClicked, setTextInputClicked] = useState(false);

  const applyErrorStyle = Boolean(
    typeof hasError === "boolean" ? hasError : hasError === ValidationState.INVALID
  );

  return (
    <div className={className} dir={dir}>
      <label
        className="flex text-gray-41 mb-1.5 text-sm"
        htmlFor="text-input"
      >
        {label}
        {mandatory && <span className="text-red-500 ml-1">*</span>}
        {languageFlag && (
          <img
            className="w-4 h-3 ml-2 rounded-sm my-auto"
            src={languageFlag === "en" ? EN : languageFlag === "fr" ? FR : ""}
            alt="country flags"
          />
        )}
      </label>
      <div
        className="relative"
        dir={errorMessage === t("number_valid") ? "ltr" : undefined}
      >
        <input
          id="text-input"
          className={`input ${!textInputClicked && "bg-gray-f6"} ${inputClassName} ${applyErrorStyle &&
            "placeholder:text-red-primary border-red-primary text-red-primary"
            }`}
          onChange={(e) => onTextInputChanged(e.target.value)}
          onClick={() => setTextInputClicked(true)}
          onBlur={() => setTextInputClicked(false)}
          value={value}
          placeholder={placeholder}
          autoComplete="off"
          onKeyPress={onKeyPress}
        />
        {applyErrorStyle && (
          <FontAwesomeIcon
            className="absolute top-1/2 -translate-y-1/2 right-4 text-red-primary"
            icon={faExclamationCircle}
          />
        )}
      </div>
      {applyErrorStyle && errorMessage && (
        <p className="text-xs text-red-primary first-letter:uppercase">
          {errorMessage}
        </p>
      )}
    </div>
  );
};

export default TextInput;
