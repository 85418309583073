import { Navigate } from "react-router-dom";
import { DEFAULT_AUTH_DATA } from "../components/auth/Login";

const PrivateRoute: React.FC<{ element: React.ReactElement }> = ({ element }) => {
  const authData = localStorage.getItem("admin_auth_data") ? JSON.parse(localStorage.getItem("admin_auth_data")!) : DEFAULT_AUTH_DATA;

  return authData.access && authData.refresh ? element : <Navigate to="/login" replace />;
};

export default PrivateRoute;
