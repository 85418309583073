import useAPI from "../../hooks/useAPI";

const TestimonialsApi = () => {
  const { sendRequest } = useAPI();

  const getTestimonials = (
    url: string,
    testimonialProcessResponse: (response: Response) => void
  ) => {
    sendRequest(
      {
        url: url,
      },
      testimonialProcessResponse
    );
  };
  const getTestimonialById = (
    _id: string | undefined,
    testimonialProcessResponse: (response: Response) => void
  ) => {
    sendRequest(
      {
        url: `testimonials/${_id}/`,
      },
      testimonialProcessResponse
    );
  };

  const deleteTestimonial = (
    testimonialProcessResponse: (response: Response) => void,
    _id: number
  ) => {
    sendRequest(
      {
        url: `testimonials/${_id}/`,
        method: "DELETE",
      },
      testimonialProcessResponse
    );
  };

  const updateStatus = (
    testimonialProcessResponse: (response: Response) => void,
    _id: number,
    _status: string
  ) => {
    sendRequest(
      {
        url: `testimonials/${_id}/`,
        method: "PATCH",
        body: JSON.stringify({
          status: _status,
        }),
      },
      testimonialProcessResponse
    );
  };
  const updateTestimonial = (
    testimonialProcessResponse: (response: Response) => void,
    _id: number,
    _formData: FormData
  ) => {
    sendRequest(
      {
        url: `testimonials/${_id}/`,
        method: "PATCH",
        body: _formData,
        contentType: true,
      },
      testimonialProcessResponse
    );
  };

  const addTestimonial = (
    _formData: FormData,
    processResponse: (res: Response) => void
  ) => {
    sendRequest(
      {
        url: "testimonials/",
        method: "POST",
        body: _formData,
        contentType: true,
      },
      processResponse
    );
  };

  return {
    getTestimonials,
    deleteTestimonial,
    updateStatus,
    addTestimonial,
    getTestimonialById,
    updateTestimonial,
  };
};

export default TestimonialsApi;
