import React, { useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/pro-regular-svg-icons";
import Dropdown, { DropdownType } from "./Dropdown";

const Pagination: React.FC<{
  dropdownKeys: string[];
  pageIndex: number;
  pageSize: number;
  dataCount: number;
  onPageChange: (newPageIndex: number) => void;
  onPageSizeChange: (newPageSize: number) => void;
}> = ({ dropdownKeys, pageIndex, pageSize, dataCount, onPageChange, onPageSizeChange }) => {
  const pageCount = Math.ceil(dataCount / pageSize);
  const startRow = pageIndex * pageSize + 1;
  const endRow = Math.min(dataCount, (pageIndex + 1) * pageSize);
  const currentPage = pageIndex + 1;

  const pageNumbers = useMemo(() => {
    const pages: (number | string)[] = [];

    if (pageCount <= 5) {
      for (let i = 1; i <= pageCount; i++) {
        pages.push(i);
      }
    } else {
      if (currentPage <= 3) {
        pages.push(1, 2, 3, 4, 5, "...");
      } else if (currentPage >= pageCount - 2) {
        pages.push("...", pageCount - 4, pageCount - 3, pageCount - 2, pageCount - 1, pageCount);
      } else {
        pages.push("...", currentPage - 2, currentPage - 1, currentPage, currentPage + 1, currentPage + 2, "...");
      }
    }

    return pages;
  }, [currentPage, pageCount]);

  const handleEllipsisClick = (isLeft: boolean) => {
    if (isLeft) {
      const targetPage = currentPage >= pageCount - 2 ? Math.max(pageCount - 5, 1) : Math.max(currentPage - 3, 1);
      onPageChange(targetPage - 1);
    } else {
      const targetPage = currentPage <= 3 ? 6 : Math.min(currentPage + 3, pageCount);
      onPageChange(targetPage - 1);
    }
  };

  return (
    <div className="flex flex-wrap justify-between items-center text-sm gap-6">
      <div className="flex items-center text-gray-8d">
        <button
          onClick={() => onPageChange(pageIndex - 1)}
          disabled={pageIndex === 0}
          className="pagination-button bg-gray-e8 mr-2"
        >
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>

        {pageNumbers.map((pageNumber, index) => {
          if (typeof pageNumber === "number") {
            return (
              <button
                key={index}
                onClick={() => onPageChange(pageNumber - 1)}
                className={`pagination-button mx-0.5 ${currentPage === pageNumber ? "bg-blue-primary text-white" : ""}`}
              >
                {pageNumber}
              </button>
            );
          } else {
            return (
              <button key={index} onClick={() => handleEllipsisClick(index === 0)} className="pagination-button mx-0.5">
                {pageNumber}
              </button>
            );
          }
        })}

        <button
          onClick={() => onPageChange(pageIndex + 1)}
          disabled={pageIndex + 1 === pageCount}
          className="pagination-button bg-gray-e8 ml-2"
        >
          <FontAwesomeIcon icon={faChevronRight} />
        </button>
      </div>

      <div className="flex items-center gap-x-5">
        <Dropdown
          type={DropdownType.SECONDARY}
          onOptionSelected={(selectedKey) => onPageSizeChange(Number(selectedKey))}
          keys={dropdownKeys.map((key) => key)}
          selectedKey={pageSize.toString()}
        />

        <span className="text-sm text-gray-700">
          Showing {startRow}-{endRow} of {dataCount}
        </span>
      </div>
    </div>
  );
};

export default Pagination;
