import React, { useEffect, useState } from "react";
import TextInput from "../../UI/TextInput";
import Dropdown, { DropdownType } from "../../UI/Dropdown";
import FileInput from "../../UI/FileInput";
import Button from "../../UI/Button";
import TrainingCandidatesAPI from "../../../store/api/TrainingCandidatesApi";
import { SnackBarType, ButtonTypes } from "../../../utils/Constants";
import Testimonial from "../../../types/Testimonial";
import { t } from "i18next";
import LanguageBox from "../../UI/LanguageBox";
import MainContainer from "../../UI/MainContainer";
import { useLocation, useNavigate } from "react-router-dom";
import TestimonialsApi from "../../../store/api/TestimonialsApi";
import CircularProgress from "@mui/material/CircularProgress";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";


export type SnackbarType = {
  message: string;
  type: number;
  show: boolean;
};

const AddEditTestimonial: React.FC = () => {
  const { state } = useLocation();
  const languages = ["en", "fr"];
  const [testimonialForm, setTestimonialForm] = useState<Testimonial>(
    state?.testimonial
      ? state?.testimonial
      : {
        id: 0,
        full_name: { en: "", fr: "" },
        role: { en: "", fr: "" },
        experience: { en: "", fr: "" },
        country: "",
        status: "",
        image: undefined,
      }
  );

  const [errors, setErrors] = useState({
    fullName: false,
    country: false,
    experience: false,
    image: false,
  });

  const [countryList, setCountryList] = useState<[string, string][]>([]);
  const { addTestimonial, updateTestimonial } = TestimonialsApi();
  const [selectedLanguage, setSelectedLanguage] = useState("en");
  const { getCountryList } = TrainingCandidatesAPI();
  const navigate = useNavigate();
  const [isPublishLoading, setIsPublishLoading] = useState(false);
  const [isDraftLoading, setIsDraftLoading] = useState(false);

  useEffect(() => {
    getCountryList(async (response: Response) => {
      if (response.ok) {
        const result = await response.json();
        setCountryList(
          result.map((country: { country__id: string; name: string }) => [
            country.country__id,
            country.name,
          ])
        );
      }
    });
  }, []);

  const handleErrorMessage = (field: { [key: string]: string }) => {
    if (languages.every((lang) => field[lang] === ""))
      return t("required_field");

    if (languages.some((lang) => field[lang] === ""))
      return t("fill_all_languages");

    return "";
  };

  const formIsValid = () => {
    let isValid = true;
    const validationErrors: any = { ...errors };

    if (!testimonialForm.country) {
      validationErrors.country = true;
      isValid = false;
    } else {
      validationErrors.country = false;
    }

    languages.forEach((lng) => {
      if (!testimonialForm.full_name[lng]) {
        validationErrors.fullName = true;
        isValid = false;
      }
      if (!testimonialForm.experience[lng]) {
        validationErrors.experience = true;
        isValid = false;
      }
    });

    setErrors(validationErrors);
    return isValid;
  };

  const createFormData = (
    testimonialForm: Testimonial,
    isPublished?: boolean,
    editMode?: boolean
  ): FormData => {
    const formData = new FormData();
    formData.append("full_name", JSON.stringify(testimonialForm.full_name));
    formData.append("role", JSON.stringify(testimonialForm.role));
    formData.append("experience", JSON.stringify(testimonialForm.experience));
    formData.append("country", testimonialForm.country);
    if (!editMode) formData.append("status", isPublished ? "PS" : "DF");

    if (testimonialForm.image && typeof testimonialForm.image !== "string")
      formData.append("image", testimonialForm.image);

    return formData;
  };

  const handleSubmit = async (isPublished: boolean) => {
    if (formIsValid()) {
      isPublished ? setIsPublishLoading(true) : setIsDraftLoading(true);
      const processResponse = async (res: Response) => {
        if (res.ok) {
          isPublished ? setIsPublishLoading(false) : setIsDraftLoading(false);
          const newSnackbar = {
            message: isPublished
              ? t("published_message")
              : t("drafted_message"),
            type: SnackBarType.ADD,
            show: true,
          };
          navigate(`/testimonials`, {
            state: { snackbar: newSnackbar },
          });
        }
      };

      const formData = createFormData(testimonialForm, isPublished);
      addTestimonial(formData, processResponse);
    }
  };

  const handleUpdateClick = async () => {
    if (formIsValid()) {
      setIsPublishLoading(true);
      const processResponse = async (res: Response) => {
        if (res.ok) {
          setIsPublishLoading(false);
          const newSnackbar = {
            message: t("updated_message"),
            type: SnackBarType.ADD,
            show: true,
          };
          navigate(`/testimonials`, {
            state: { snackbar: newSnackbar },
          });
        }
      };

      const formData = createFormData(testimonialForm, true);
      updateTestimonial(processResponse, state?.testimonial.id, formData);
    }
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files ? e.target.files[0] : undefined;

    if (selectedFile) {
      const validFormats = ["image/jpeg", "image/png"];
      const maxFileSizeMB = 5;
      const fileSizeInMB = selectedFile.size / (1024 * 1024);

      if (
        !validFormats.includes(selectedFile.type) ||
        fileSizeInMB > maxFileSizeMB
      ) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          image: true,
        }));
        return;
      }

      const profileiImage = new Image();
      profileiImage.src = URL.createObjectURL(selectedFile);
      profileiImage.onload = () => {
        setTestimonialForm((prev) => ({ ...prev, image: selectedFile }));
        setErrors((prevErrors) => ({
          ...prevErrors,
          image: false,
        }));
      };

      profileiImage.onerror = () => {
        setErrors((prevErrors) => ({
          ...prevErrors,
          image: true,
        }));
      };
    }
  };

  return (
    <MainContainer>
      <main className="alignment mt-6">
        <header>
          <h3 className="text-lg font-semibold text-gray-27 my-auto">
            {state?.testimonial ? t("edit_testimonial") : t("add_testimonial")}
          </h3>
        </header>
        <div className="relative bg-white rounded shadow p-6 mt-6">
          <div>
            <span className="flex justify-between mb-6 items-center">
              <p className="text-sm text-gray-41">{t("fill_language")}</p>
              <LanguageBox
                onChange={(lng) => setSelectedLanguage(lng)}
                selectedLanguage={selectedLanguage}
              />
            </span>
            <div className="grid grid-cols-1 gap-4 mb-8 ">
              <TextInput
                label={t("full_name")}
                languageFlag={selectedLanguage}
                mandatory
                placeholder={t("full_name")}
                value={testimonialForm.full_name[selectedLanguage]}
                onTextInputChanged={(e) => {
                  setTestimonialForm((prev) => ({
                    ...prev,
                    full_name: {
                      ...prev.full_name,
                      [selectedLanguage]: e,
                    },
                  }));
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    full_name: false,
                  }));
                }}
                hasError={errors.fullName}
                errorMessage={handleErrorMessage(testimonialForm.full_name)}
                inputClassName="h-10 input-style"
              />
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-8">
              <TextInput
                label={t("role")}
                languageFlag={selectedLanguage}
                placeholder={t("role")}
                value={testimonialForm.role?.[selectedLanguage]}
                onTextInputChanged={(e) =>
                  setTestimonialForm((prev) => ({
                    ...prev,
                    role: {
                      ...prev.role,
                      [selectedLanguage]: e,
                    },
                  }))
                }
                hasError={false}
                errorMessage={""}
                inputClassName="h-10 input-style"
              />
              <Dropdown
                type={DropdownType.PRIMARY}
                keys={countryList.map((country) => country[1])}
                selectedKey={
                  countryList.find(
                    (c) => c[0] === testimonialForm.country
                  )?.[1] || ""
                }
                onOptionSelected={(selectedName) => {
                  const selectedCountry = countryList.find(
                    (c) => c[1] === selectedName
                  );
                  if (selectedCountry) {
                    setTestimonialForm({
                      ...testimonialForm,
                      country: selectedCountry[0],
                    });
                  }
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    country: false,
                  }));
                }}
                rootClassname="w-auto !h-10 input-style"
                selectedOptionClassname="text-sm"
                textClassname="leading-[14px]"
                placeholder={t("choose_country")}
                hasError={errors.country}
                label={t("country")}
                languageFlag={selectedLanguage}
                mandatory
                errorMessage={errors.country ? t("required_field") : ""}
                extraAvailableOptionsContainerClassName="max-h-28 overflow-y-auto"
                filterable={true}
              />
            </div>

            <div className="mb-8">
              <TextInput
                label={t("experience")}
                languageFlag={selectedLanguage}
                mandatory
                placeholder={t("experience")}
                value={testimonialForm.experience[selectedLanguage]}
                onTextInputChanged={(e) => {
                  setTestimonialForm((prev) => ({
                    ...prev,
                    experience: {
                      ...prev.experience,
                      [selectedLanguage]: e,
                    },
                  }));
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    experience: false,
                  }));
                }}
                hasError={errors.experience}
                errorMessage={handleErrorMessage(testimonialForm.experience)}
                inputClassName="!h-[60px] !pb-6 input-style"
              />
            </div>

            <div className="mb-14">
              {/*edit mode*/}
              <label className="block text-sm text-gray-41 mb-2">
                {t("image")}
              </label>

              {testimonialForm.image && state?.testimonial ? (
                <UploadedImage
                  image={testimonialForm.image}
                  src={
                    typeof testimonialForm.image === "string"
                      ? `${process.env.REACT_APP_BASE_URL_API?.replace(
                        "api/",
                        ""
                      )}${testimonialForm.image}`
                      : URL.createObjectURL(testimonialForm.image)
                  }
                  onRemove={() =>
                    setTestimonialForm({
                      ...testimonialForm,
                      image: undefined,
                    })
                  }
                  altText="profile"
                />
              ) : (
                //add mode
                <div className="mt-2">
                  {!testimonialForm.image ? (
                    <FileInput
                      label=""
                      mandatory
                      onFileChange={handleFileChange}
                      file={undefined}
                      hasError={errors.image}
                      errorMessage={errors.image ? t("image_format") : ""}
                      accept="image/*"
                      showFileName={false}
                    />
                  ) : (
                    <UploadedImage
                      image={testimonialForm.image}
                      src={
                        typeof testimonialForm.image === "string"
                          ? `${process.env.REACT_APP_BASE_URL_API?.replace(
                            "api/",
                            ""
                          )}${testimonialForm.image}`
                          : URL.createObjectURL(testimonialForm.image)
                      }
                      onRemove={() =>
                        setTestimonialForm({
                          ...testimonialForm,
                          image: undefined,
                        })
                      }
                      altText="profile"
                    />
                  )}
                </div>
              )}
            </div>
            <div
              className={`flex w-full ${state?.testimonial ? "justify-end" : "justify-between"
                }`}
            >
              {!state?.testimonial && (
                <Button
                  type={ButtonTypes.SECONDARY}
                  onClick={() => handleSubmit(false)}
                  className="mr-auto"
                  disabled={isPublishLoading || isDraftLoading}
                >
                  {isDraftLoading ? (
                    <CircularProgress
                      size={15}
                      color="primary"
                      className="mx-8"
                    />
                  ) : (
                    t("save_as_draft")
                  )}
                </Button>
              )}
              <span>
                <Button
                  type={ButtonTypes.CANCEL}
                  onClick={() => navigate(`/testimonials`)}
                >
                  {t("cancel")}
                </Button>
                <Button
                  type={ButtonTypes.PRIMARY}
                  onClick={() =>
                    state?.testimonial
                      ? handleUpdateClick()
                      : handleSubmit(true)
                  }
                  className="ml-4"
                  disabled={isPublishLoading || isDraftLoading}
                >
                  {isPublishLoading ? (
                    <CircularProgress
                      size={15}
                      color="inherit"
                      className="mx-4"
                    />
                  ) : state?.testimonial ? (
                    t("update")
                  ) : (
                    t("publish")
                  )}
                </Button>
              </span>
            </div>
          </div>
        </div>
      </main>
    </MainContainer>
  );
};

export default AddEditTestimonial;

const UploadedImage: React.FC<{
  image: File | string;
  onRemove: () => void;
  src?: string;
  altText?: string;
}> = ({ image, onRemove, src, altText }) => {
    return (
      <div className="flex items-center justify-between mt-2 border border-gray-f6 rounded-sm px-4 py-2 h-[70px]">
        <span className="flex">
          <img src={src} alt={altText} className="w-12 h-12 object-cover" />
          {typeof image === "object" && (
            <p className="text-sm text-gray-41 w-max ml-2 my-auto">
              {image.name}
            </p>
          )}
        </span>
        <FontAwesomeIcon
          icon={faTrash}
          className="text-blue-primary cursor-pointer"
          onClick={onRemove}
        />
      </div>
    );
  };
