import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/pro-light-svg-icons";
import { useTranslation } from "react-i18next";
import { ValidationState } from "../../utils/Constants";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import EN from "../../images/english.svg";
import FR from "../../images/french.svg";

export enum DropdownType {
  PRIMARY,
  SECONDARY,
}

interface DropdownProps {
  type: DropdownType;
  keys: string[];
  selectedKey: string;
  onOptionSelected: (key: string) => void;
  getValueByKey?: (key: string) => string;
  extraAvailableOptionsContainerClassName?: string;
  rootClassname?: string;
  selectedOptionClassname?: string;
  textClassname?: string;
  placeholder?: string;
  hasError?: ValidationState | boolean;
  errorMessage?: string;
  label?: string;
  mandatory?: boolean;
  languageFlag?: string;
  filterable?: boolean;
}

const styles = {
  primary: {
    root: "w-[260px]",
    selectedOption:
      "flex justify-between items-center py-[9px] px-4 border border-gray-df rounded cursor-pointer hover:bg-white bg-gray-f6 focus:bg-white focus:border-blue-71 focus:caret-blue-71",
    clickedState: "border border-blue-71 bg-white",
    text: "text-sm capitalize text-black41",
    option:
      "px-4 py-2 capitalize text-sm text-black41 cursor-pointer hover:bg-gray-f6",
    optionsContainer: "top-10",
  },
  secondary: {
    root: "w-[72px]",
    selectedOption:
      "flex justify-between items-center p-2 border border-gray-df rounded cursor-pointer bg-white",
    clickedState: "border-blue-71 bg-white",
    text: "text-black41 text-sm",
    option:
      "p-2 capitalize text-sm text-black41 cursor-pointer hover:bg-gray-f6",
    optionsContainer: "top-10",
  },
};

const Dropdown: React.FC<DropdownProps> = ({
  type,
  keys,
  selectedKey,
  onOptionSelected,
  getValueByKey,
  extraAvailableOptionsContainerClassName,
  rootClassname,
  selectedOptionClassname,
  textClassname,
  placeholder,
  hasError,
  errorMessage,
  label,
  mandatory,
  languageFlag,
  filterable,
}) => {
  const [showMenu, setShowMenu] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const { t } = useTranslation();
  const dropdownRef = useRef<HTMLDivElement>(null);

  const currentStyles =
    styles[type === DropdownType.PRIMARY ? "primary" : "secondary"];

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setShowMenu(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setSearchQuery(selectedKey);
  }, [selectedKey]);

  const handleInputClick = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  const applyErrorStyle =
    typeof hasError === "boolean"
      ? hasError
      : hasError === ValidationState.INVALID;

  const filteredKeys = searchQuery
    ? keys.filter((key) =>
        getValueByKey
          ? getValueByKey(key).toLowerCase().includes(searchQuery.toLowerCase())
          : t(key.toLowerCase()).includes(searchQuery.toLowerCase())
      )
    : keys;

  return (
    <span>
      {label && (
        <label className="flex text-gray-41 mb-[6px] text-sm">
          {label}
          {mandatory && (
            <span className="flex">
              <span className="text-red-500 ml-1">*</span>
              <img
                className="w-[16px] h-[11px] my-auto ml-2 rounded-sm"
                src={
                  languageFlag === "en" ? EN : languageFlag === "fr" ? FR : ""
                }
                alt="country flags"
              />
            </span>
          )}
        </label>
      )}

      <div
        className={`relative ${rootClassname} ${currentStyles.root}`}
        ref={dropdownRef}
      >
        {filterable ? (
          <div className="relative" onClick={() => setShowMenu(!showMenu)}>
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className={`${selectedOptionClassname} ${
                currentStyles.selectedOption
              } ${
                showMenu && currentStyles.clickedState
              } w-full placeholder:text-gray-d7 outline-none`}
              onClick={handleInputClick}
              onFocus={() => setShowMenu(true)}
              placeholder={placeholder}
            />
            <div
              className={`absolute top-[22px] right-4 -translate-y-1/2 flex w-auto gap-2 items-center ${
                showMenu && "-rotate-180"
              }`}
            >
              <FontAwesomeIcon icon={faAngleDown} className="text-gray-b5" />
              {applyErrorStyle && (
                <FontAwesomeIcon
                  className="top-1/2 text-red-primary"
                  icon={faExclamationCircle}
                />
              )}
            </div>
          </div>
        ) : (
          <div
            className={`${selectedOptionClassname} ${
              currentStyles.selectedOption
            } ${showMenu && currentStyles.clickedState}`}
            onClick={() => setShowMenu(!showMenu)}
            aria-expanded={showMenu}
            aria-haspopup="listbox"
            role="button"
          >
            <p className={`${textClassname} ${currentStyles.text}`}>
              {getValueByKey
                ? getValueByKey(selectedKey)
                : t(selectedKey.toLowerCase())}

              {!getValueByKey && !selectedKey ? (
                <p className="!text-gray-d7">{placeholder}</p>
              ) : (
                ""
              )}
            </p>
            <div className="leading-[14px] flex w-auto gap-2 items-center">
              <FontAwesomeIcon icon={faAngleDown} className="text-gray-b5" />
              {applyErrorStyle && (
                <FontAwesomeIcon
                  className="top-1/2 text-red-primary"
                  icon={faExclamationCircle}
                />
              )}
            </div>
          </div>
        )}

        {applyErrorStyle && errorMessage && (
          <p className="text-xs text-red-primary first-letter:uppercase">
            {errorMessage}
          </p>
        )}

        {showMenu && (
          <ul
            className={`absolute left-0 right-0 bg-white rounded shadow py-1 z-40 ${
              currentStyles.optionsContainer
            } ${extraAvailableOptionsContainerClassName || ""}`}
            role="listbox"
          >
            {(filterable ? filteredKeys : keys).map((key) => (
              <li
                key={key}
                className={currentStyles.option}
                onClick={() => {
                  onOptionSelected(key);
                  setShowMenu(false);
                  setSearchQuery("");
                }}
                role="option"
                aria-selected={key === selectedKey}
              >
                {getValueByKey ? getValueByKey(key) : t(key.toLowerCase())}
              </li>
            ))}
          </ul>
        )}
      </div>
    </span>
  );
};

export default Dropdown;
