import React, { useState, useEffect, useRef, ReactNode, useMemo } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/pro-regular-svg-icons";
import LogoH from "../../images/logo-h.svg";
import LastConfirmWarningModal from "./LastConfirmWarningModal";
import { DEFAULT_AUTH_DATA } from "../auth/Login";
import LogoV from "../../images/logo-v.png";

interface MainContainerProps {
  children: ReactNode;
}

const MainContainer: React.FC<MainContainerProps> = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const authData = localStorage.getItem("admin_auth_data")
    ? JSON.parse(localStorage.getItem("admin_auth_data")!)
    : DEFAULT_AUTH_DATA;
  const [showLangs, setShowLangs] = useState(false);
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const [showMobileNavigation, setShowMobileNavigtaion] = useState(false);
  const [showLogoutWarningModal, setShowLogoutWarningModal] = useState(false);
  const [showFixedNavigationHeader, setShowFixedNavigationHeader] =
    useState(false);
  const navigationHeaderContainer = useRef<HTMLElement>(null);

  const headerTabs = useMemo(
    () => [
      { route: "/contacts", title: "contacts" },
      { route: "/subscribers", title: "newsletter_subscribers" },
      { route: "/training-candidates", title: "training_candidates" },
      { route: "/testimonials", title: "testimonial" },
    ],
    []
  );

  useEffect(() => {
    if (showLangs) setShowProfileMenu(false);
  }, [showLangs]);

  useEffect(() => {
    if (showProfileMenu) setShowLangs(false);
  }, [showProfileMenu]);

  useEffect(() => {
    const navigationHeaderTop = navigationHeaderContainer.current?.offsetTop;
    const navigationHeaderHeight =
      navigationHeaderContainer.current?.offsetHeight;

    const onWindowScroll = () => {
      if (window.innerWidth >= 768) {
        window.scrollY >= navigationHeaderTop! + navigationHeaderHeight!
          ? !showFixedNavigationHeader && setShowFixedNavigationHeader(true)
          : setShowFixedNavigationHeader(false);
      }
    };
    window.addEventListener("scroll", onWindowScroll);

    return () => {
      window.removeEventListener("scroll", onWindowScroll);
    };
  }, [showFixedNavigationHeader]);

  return (
    <div className="flex flex-col min-h-screen">
      <div className="bg-gray-f5 z-30 fixed w-full top-0 left-0 md:static">
        <header className="alignment flex items-center justify-between py-4 md:py-6">
          <img
            data-testid="header-logo"
            className="w-44 md:w-auto"
            src={LogoH}
            alt="CIMA"
          />
          <div className="hidden items-center md:flex flex-col gap-2">
            <p className="text-black27 text-sm">{t("welcome", { authData })}</p>
            <button
              onClick={() => {
                setShowLogoutWarningModal(true);
              }}
              className="text-sm hover:underline duration-200"
            >
              {t("logout")}
            </button>
          </div>
          <div className="flex items-center gap-x-6 md:hidden">
            <p className="text-black27 text-sm">{t("welcome", { authData })}</p>
            <button
              onClick={() => {
                setShowLogoutWarningModal(true);
              }}
              className="text-sm hover:underline duration-200"
            >
              {t("logout")}
            </button>
            <FontAwesomeIcon
              className="text-blue-primary cursor-pointer"
              icon={faBars}
              onClick={() => setShowMobileNavigtaion(true)}
            />
            <MobileNavigationModal
              headerTabs={headerTabs}
              sidebarClassName={`${
                showMobileNavigation ? "translate-x-0" : "-translate-x-full"
              }`}
              backdropClassName={`${showMobileNavigation ? "block" : "hidden"}`}
              onBackdrop={() => setShowMobileNavigtaion(false)}
            />
          </div>
        </header>

        <header ref={navigationHeaderContainer} className="bg-blue-primary">
          <nav className="alignment py-4 hidden md:flex">
            {headerTabs.map((link, index) => (
              <NavLink
                key={index}
                className={({ isActive }) =>
                  `text-white px-4 py-2 rounded mx-2 hover:bg-white51 ${
                    isActive && "bg-white51"
                  }`
                }
                to={link.route}
              >
                {t(link.title)}
              </NavLink>
            ))}
          </nav>
        </header>

        <header
          className={`bg-white shadow-lg fixed top-0 w-full duration-500 ${
            showFixedNavigationHeader
              ? "translate-y-0"
              : " -translate-y-[160px]"
          }`}
        >
          <nav className="alignment py-4 hidden md:flex">
            {headerTabs.map((link, index) => (
              <NavLink
                key={index}
                className={({ isActive }) =>
                  `text-blue-primary px-4 py-2 rounded mx-2 hover:bg-blue-e1 ${
                    isActive && "bg-blue-e1"
                  }`
                }
                to={link.route}
              >
                {t(link.title)}
              </NavLink>
            ))}
          </nav>
        </header>
      </div>

      <main
        className={`flex-1 w-full relative pt-[60px] md:pt-0 ${
          showFixedNavigationHeader && "md:pt-[104px]"
        }`}
      >
        {props.children}
      </main>

      <p dir="ltr" className="alignment text-sm text-gray-77 my-4 flex items-center 888:h-[7%] justify-end">
            2024&copy; <b>(CIMA)</b>
      </p>

      {showLogoutWarningModal && (
        <LastConfirmWarningModal
          onCancel={() => setShowLogoutWarningModal(false)}
          onConfirm={() => {
            localStorage.removeItem("admin_auth_data");
            navigate("/login", { replace: true });
          }}
          onBackdrop={() => setShowLogoutWarningModal(false)}
          title="logout"
          message="logout_message"
          confirmButtonLabel="yes_logout"
        />
      )}
    </div>
  );
};

export default MainContainer;

const MobileNavigationModal: React.FC<{
  headerTabs: { route: string; title: string }[];
  sidebarClassName: string;
  backdropClassName: string;
  onBackdrop: () => void;
}> = ({ headerTabs, sidebarClassName, backdropClassName, onBackdrop }) => {
  const { t } = useTranslation();

  return (
    <>
      <div
        className={`w-screen h-screen bg-gray-4147 fixed top-0 left-0 z-30 cursor-pointer md:hidden ${backdropClassName}`}
        onClick={() => onBackdrop()}
      />
      <aside
        className={`w-64 h-screen bg-blue-primary fixed top-0 left-0 duration-500 z-40 ${sidebarClassName}`}
      >
        <div className="bg-white flex justify-center py-14 px-4">
          <img src={LogoV} alt="CIMA" />
        </div>
        <nav className="flex flex-col mt-3">
          {headerTabs.map(({ route, title }, index) => (
            <NavLink
              key={index}
              className="text-white px-4 py-2 my-2 hover:bg-white51"
              to={route}
              onClick={onBackdrop}
            >
              {t(title)}
            </NavLink>
          ))}
        </nav>
      </aside>
    </>
  );
};
