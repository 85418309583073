import React from "react";
import noData from "../../images/NoData.svg";
import { useTranslation } from "react-i18next";

const NoResultMessage: React.FC<{}> = () => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center mt-20">
      <img src={noData} alt={t("result_not_found")} />
      <p className="mt-8 font-semibold text-sm">{t("result_not_found")}</p>
    </div>
  );
};

export default NoResultMessage;
