import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import AuthAPI from "../../store/api/authAPI";
import useEmailValidation from "../../hooks/UseEmailValidation";
import captchaValidation from "../../utils/captchaValidation";
import { ValidationState, ButtonTypes } from "../../utils/Constants";
import TextInput from "../UI/TextInput";
import Button from "../UI/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEyeSlash, faEye, faExclamationTriangle, faTimes } from "@fortawesome/pro-light-svg-icons";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import backgroundImage from "../../images/background.png";
import logoImage from "../../images/logo-v.png";

export type AuthDataType = {
  access: string;
  refresh: string;
  firstName: string;
  lastName: string;
  avatar: string;
  email: string;
  lang: string;
  role: string;
};

export const DEFAULT_AUTH_DATA = {
  access: "",
  refresh: "",
  firstName: "",
  lastName: "",
  avatar: "",
  email: "",
  lang: "en",
  role: "",
};

export const enum errorType {
  CAPTCHA,
  EMAIL,
  PASSWORD,
  BLOCKED,
  EMAIL_FORMAT,
  NONE,
}

export type Error = {
  type: errorType;
  message?: string;
  remainingAttempts?: number;
};

export const ERROR_DEFAULT_VALUE = {
  type: errorType.NONE,
};

const CAPTCHA_ACTION = "login__submit";

const Login = () => {
  const navigate = useNavigate();
  const { loginRequest } = AuthAPI();
  const [password, setPassword] = useState<string>();
  const [error, setError] = useState<Error>(ERROR_DEFAULT_VALUE);

  const { email, setEmail, emailHasError, setEmailHasError } = useEmailValidation();

  const [emailIsEmpty, setEmailIsEmpty] = useState(false);
  const [passwordIsEmpty, setPasswordIsEmpty] = useState(false);
  const [loading, setLoading] = useState(false);
  const [somethingWentWrong, setSomethingWentWrong] = useState(false);

  useEffect(() => {
    if (emailHasError === ValidationState.INVALID && email !== "") {
      setError({
        type: errorType.EMAIL_FORMAT,
        message: "The email address is incorrect.",
      });
    }
  }, [emailHasError, email]);

  useEffect(() => {
    setEmailIsEmpty(email === "");
  }, [email]);

  useEffect(() => {
    setPasswordIsEmpty(password === "");
  }, [password]);

  const onLoginFormSubmitted = (event?: React.FormEvent) => {
    event?.preventDefault();
    setLoading(true);

    captchaValidation(
      CAPTCHA_ACTION,
      (token) => {
        if (email && password) loginRequest(email, password, token, processLoginResponse);
      },
      () => {
        setLoading(false);
        setSomethingWentWrong(true);
      }
    );
  };

  const processLoginResponse = async (response: Response) => {
    const result = await response.json();
    setLoading(false);

    if (response.ok) {
      setError(ERROR_DEFAULT_VALUE);

      localStorage.setItem(
        "admin_auth_data",
        JSON.stringify({
          access: result.access,
          refresh: result.refresh,
          firstName: result["first_name"],
          lastName: result["last_name"],
          avatar: result.photo,
          email: result.email,
          lang: result["preferred_language"],
          role: result["role"],
        })
      );
      navigate("/", { replace: true });
    } else {
      switch (response.status) {
        case 400:
          if ("email" in result) {
            setEmailIsEmpty(true);
          }

          if ("password" in result) {
            setPasswordIsEmpty(true);
          }
          break;
        case 401:
          setError({
            type: errorType.PASSWORD,
            message: result.password,
          });
          break;
        case 403:
          setError({
            type: errorType.BLOCKED,
            message: result.lock,
          });
          break;
        case 404:
          setError({
            type: errorType.EMAIL,
            message: result.email,
          });
          break;
        case 406:
          setError({
            type: errorType.CAPTCHA,
            message: result.captcha,
          });
          break;
      }
    }
  };

  let emailErrorMessage = "";
  if (emailIsEmpty) {
    emailErrorMessage = "email is required.";
  } else {
    if (error.type === errorType.EMAIL_FORMAT) {
      emailErrorMessage = error.message || "";
    }
  }

  return (
    <main className="page">
      <img
        className="w-auto h-full absolute right-0 bottom-0"
        src={backgroundImage}
        alt=""
      />
      <section className="w-95/100 max-w-[490px] flex flex-col bg-white rounded p-8 z-10">
        <img className="w-40 mx-auto" src={logoImage} alt="CIMA" />


      <form className="flex flex-col mt-[84px]" onSubmit={onLoginFormSubmitted}>
        {error.type !== errorType.NONE && error.type !== errorType.EMAIL_FORMAT && error.type !== errorType.BLOCKED && (
          <ErrorBanner message={error.message || ""} />
        )}
        {error.type === errorType.BLOCKED &&
          <div>
            <ErrorBanner message={error.message!} />
            <p className="text-center text-sm text-gray-8d mt-4 first-letter:uppercase">
              please contact the support <b>(support@cima.com)</b> to assist you with
              that.
            </p>
          </div>}
        <TextInput
          value={email || ""}
          placeholder="email"
          className="mt-5"
          errorMessage={emailErrorMessage}
          hasError={emailIsEmpty || error.type === errorType.EMAIL || error.type === errorType.EMAIL_FORMAT}
          onTextInputChanged={(value) => {
            setEmail(value);
            if (error.type !== errorType.PASSWORD) {
              setError(ERROR_DEFAULT_VALUE);
              setEmailHasError(ValidationState.WAITING);
            }
          }}
        />
        <PasswordInput
          hasError={passwordIsEmpty || error.type === errorType.PASSWORD}
          onPasswordChange={(value) => {
            setPassword(value);
            if (error.type !== errorType.EMAIL) {
              setError(ERROR_DEFAULT_VALUE);
            }
          }}
          placeholder="password"
        />
        <div className={`flex items-center ${passwordIsEmpty ? "justify-between" : "justify-end"}`}>
          {passwordIsEmpty && (
            <p className="text-xs text-red-primary mt-2 first-letter:uppercase">password is required.</p>
          )}
        </div>
        <Button
          type={ButtonTypes.PRIMARY}
          className="mt-8"
          disabled={
            email === undefined ||
            password === undefined ||
            emailIsEmpty ||
            passwordIsEmpty ||
            error.type !== errorType.NONE
          }
          dataAction={CAPTCHA_ACTION}
        >
          {loading ? <CircularProgress size={18} style={{ color: "white" }} /> : "login"}
        </Button>
      </form>
      {somethingWentWrong && (
        <AlertModal
          onAction={() => window.location.reload()}
          onBackdrop={() => setSomethingWentWrong(false)}
        />
      )}
    </section>
    </main>
  );
};

export default Login;

const PasswordInput: React.FC<{
  hasError: boolean;
  onPasswordChange: (value: string) => void;
  placeholder: string;
  disabled?: boolean;
}> = ({ hasError, onPasswordChange, placeholder, disabled }) => {
  const [showPassword, setShowPassword] = useState(false);
  const passwordInputContainer = useRef<HTMLInputElement>(null);

  return (
    <div className="mt-4 relative" dir="ltr">
      {showPassword ? (
        <FontAwesomeIcon
          className="absolute top-[14.5px] right-4 cursor-pointer text-gray-27"
          icon={faEye}
          onClick={() => {
            setShowPassword(false);
            passwordInputContainer.current!.type = "password";
          }}
        />
      ) : (
        <FontAwesomeIcon
          className="absolute top-[14.5px] right-4 cursor-pointer text-gray-27"
          icon={faEyeSlash}
          onClick={() => {
            setShowPassword(true);
            passwordInputContainer.current!.type = "text";
          }}
        />
      )}
      {hasError && (
        <FontAwesomeIcon
          className="absolute top-[14.5px] right-[42px] text-red-primary"
          icon={faExclamationCircle}
        />
      )}
      <input
        ref={passwordInputContainer}
        className={`input w-full pr-16 placeholder:capitalize ${hasError && "placeholder:text-red-primary border-red-primary text-red-primary"
          }`}
        type="password"
        placeholder={placeholder}
        disabled={disabled}
        onChange={(e) => {
          onPasswordChange(e.target.value);
        }}
      />
    </div>
  );
};

const ErrorBanner: React.FC<{ message: string; className?: string }> = (
  props
) => {
  const { message, className } = props;

  return (
    <div
      className={`py-3 px-4 rounded bg-red-primary40 text-red-primary flex items-center ${className}`}
    >
      <FontAwesomeIcon icon={faExclamationTriangle} />
      <p className="ml-3 text-sm first-letter:uppercase">{message}</p>
    </div>
  );
};

const AlertModal: React.FC<{
  onAction: () => void;
  onBackdrop: () => void;
}> = ({ onAction, onBackdrop }) => {
  const { t } = useTranslation();

  return (
    <div className="backdrop" onClick={() => onBackdrop()}>
      <section
        className="cursor-auto shadow-md bg-white w-full 480:w-auto 480:rounded"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="relative text-center">
          <FontAwesomeIcon
            icon={faTimes}
            className="absolute top-5 right-5 text-gray-41 text-sm cursor-pointer"
            onClick={() => onBackdrop()}
          />
            <FontAwesomeIcon
              icon={faExclamationCircle}
              className="text-8xl mt-5 text-red-primary"
            />
          <h3
            className="text-xl font-bold text-red-primary first-letter:uppercase"
          >
            {t("something_went_wrong")}
          </h3>
        </div>
        <div className="bg-gray-d725 mt-7 p-8 text-center">
          <p className="text-sm text-gray-41">{t("something_went_wrong_message")}</p>
            <Button
              className="mt-5"
              type={ButtonTypes.ERROR}
              onClick={() => onAction()}
            >
              {t("refresh")}
            </Button>
        </div>
      </section>
    </div>
  );
};
