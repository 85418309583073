import React from "react";
import "./App.css";
import { Routes, Navigate, Route } from "react-router-dom";
import PrivateRoute from "./utils/PrivateRoute";
import Login from "./components/auth/Login";
import NotFound404 from "./components/main/not-found-404/NotFound404";
import ContactsList from "./components/main/contacts/ContactsList";
import ContactDetails from "./components/main/contacts/ContactDetails";
import TrainingCandidatesList from "./components/main/training-candidates/TrainingCandidatesList";
import TrainingCandidateDetails from "./components/main/training-candidates/TrainingCandidateDetails";
import SubscribersList from "./components/main/subscribers/SubscribersList";
import AddEditTestimonial from "./components/main/testimonial/AddEditTestimonial";
import TestimonialsList from "./components/main/testimonial/TestimonialsList";

const App: React.FC = () => {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="*" element={<NotFound404 />} />
      <Route
        path="/contacts"
        element={<PrivateRoute element={<ContactsList />} />}
      />
      <Route
        path="/contact/:id"
        element={<PrivateRoute element={<ContactDetails />} />}
      />
      <Route
        path="/training-candidates"
        element={<PrivateRoute element={<TrainingCandidatesList />} />}
      />
      <Route
        path="/training-candidate/:id"
        element={<PrivateRoute element={<TrainingCandidateDetails />} />}
      />
      <Route
        path="/subscribers"
        element={<PrivateRoute element={<SubscribersList />} />}
      />
      <Route path="/" element={<Navigate to="/contacts" replace />} />
      <Route
        path="/testimonials"
        element={<PrivateRoute element={<TestimonialsList />} />}
      />
      <Route
        path="/testimonials/testimonial"
        element={<PrivateRoute element={<AddEditTestimonial />} />}
      />
    </Routes>
  );
};

export default App;
