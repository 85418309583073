import React, { useEffect, useMemo, useRef, useState } from "react";
import MainContainer from "../../UI/MainContainer";
import Button from "../../UI/Button";
import {
  ButtonTypes,
  SnackBarType,
  StatusBadgeTypes,
} from "../../../utils/Constants";
import SearchSection from "../../UI/SearchSection";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faPlus } from "@fortawesome/pro-light-svg-icons";
import {
  faUpload,
  faFileAlt,
  faPen,
  faTrash,
  faEllipsisV,
} from "@fortawesome/pro-regular-svg-icons";
import { useTranslation } from "react-i18next";
import { CircularProgress, Snackbar, SnackbarContent } from "@mui/material";
import NoResultMessage from "../../UI/NoResultMessage";
import LastConfirmWarningModal from "../../UI/LastConfirmWarningModal";
import Pagination from "../../UI/Pagination";
import Table from "../../UI/Table";
import StatusBadge from "../../UI/StatusBadge";
import { Column, Row } from "react-table";
import {
  renderSortableHeader,
  SortConfig,
} from "../../../utils/renderSortableHeader";
import { useLocation, useNavigate } from "react-router-dom";
import TestimonialsApi from "../../../store/api/TestimonialsApi";
import TestimonialType from "../../../types/Testimonial";
import { createPortal } from "react-dom";
import TestimonialPreview from "./TestimonialPreview";

export type SnackbarType = {
  message: string;
  type: number;
  show: boolean;
};

const Testimonial: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [showLoading, setShowLoading] = useState(false);
  const [searchInput, setSearchInput] = useState<string>("");
  const [responseData, setResponseData] = useState<TestimonialType[]>([]);
  const [snackbar, setSnackbar] = useState<SnackbarType>({
    message: "",
    type: 0,
    show: false,
  });
  const [pageIndex, setPageIndex] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [dataCount, setDataCount] = useState<number>(0);
  const [sortConfig, setSortConfig] = useState<SortConfig | undefined>(
    undefined
  );
  const [activeRow, setActiveRow] = useState<{
    id: number;
    status: string | null;
  } | null>(null);
  const [actionMenuPosition, setActionMenuPosition] = useState<{
    top: number;
    left: number;
  } | null>(null);
  const [selectedTestimonial, setSelectedTestimonial] = useState<number | null>(
    null
  );
  const [showPreview, setShowPreview] = useState(false);
  const [showActionModal, setShowActionModal] = useState(false);
  const [modalAction, setModalAction] = useState<
    "publish" | "draft" | "delete" | null
  >(null);
  const { getTestimonials, updateStatus, deleteTestimonial } =
    TestimonialsApi();
  const actionMenuRef = useRef<HTMLDivElement | null>(null);

  const getTestimonialsList = () => {
    setShowLoading(true);
    getTestimonials(
      `${"testimonials/"}${constructQueryParams()}`,
      async (response: Response) => {
        const result = await response.json();
        if (!response.ok) {
          return;
        }
        setResponseData(result.testimonials);
        setDataCount(result.total_count);
        setShowLoading(false);
      }
    );
  };

  const constructQueryParams = (includePagination: boolean = true) => {
    let queryParams: string[] = [];
    if (includePagination)
      queryParams.push(`page_number=${pageIndex + 1}&page_size=${pageSize}`);
    if (searchInput) queryParams.push(`search_testimonial=${searchInput}`);
    if (sortConfig)
      queryParams.push(
        `sort_by=${sortConfig.column === "country_name" ? "country" : sortConfig.column
        }&sort_type=${sortConfig.sortType}`
      );
    return `?${queryParams.join("&")}`;
  };

  useEffect(() => {
    setPageIndex(0);
  }, [searchInput]);

  useEffect(() => {
    getTestimonialsList();
  }, [pageIndex, pageSize, searchInput, sortConfig]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        actionMenuRef.current &&
        !actionMenuRef.current.contains(event.target as Node)
      )
        setActiveRow(null);
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (state?.snackbar) {
      setSnackbar(state.snackbar);

      navigate(`/testimonials`, {
        replace: true,
        state: {},
      });
    }
  }, [state, navigate]);

  const columns: Column<TestimonialType>[] = useMemo(
    () => [
      {
        Header: (props) => (
          <div className="flex items-center">
            {renderSortableHeader(props.column, t("full_name"), sortConfig, setSortConfig)}
          </div>
        ),
        accessor: (row) => row.full_name,
        id: "full_name",
        Cell: ({ row }: { row: Row<TestimonialType> }) => {
          const full_name = row.original.full_name?.en || "N/A";
          return (
            <span className="truncate">{full_name}</span>
          );
        },
      },
      {
        Header: (props) =>
          renderSortableHeader(props.column, t("role"), sortConfig, setSortConfig),
        accessor: "role",
        Cell: ({ row }: { row: Row<TestimonialType> }) => {
          const role = row.original.role?.en || "N/A";
          return <span>{role}</span>;
        },
      },
      {
        Header: (props) =>
          renderSortableHeader(props.column, t("country"), sortConfig, setSortConfig),
        accessor: "country_name",
      },
      {
        Header: (props) =>
          renderSortableHeader(props.column, t("status"), sortConfig, setSortConfig),
        accessor: "status",
        Cell: ({ value }: { value: string }) => {
          let statusType: StatusBadgeTypes | undefined;
          switch (value) {
            case "PS":
              statusType = StatusBadgeTypes.PUBLISHED;
              break;
            case "DF":
              statusType = StatusBadgeTypes.DRAFT;
              break;
            default:
              statusType = undefined;
          }
          return statusType ? (
            <StatusBadge type={statusType} />
          ) : (
            <span>{t("unknown_status")}</span>
          );
        },
      },
      {
        Header: "",
        id: "actions",
        Cell: ({ row }: { row: any }) => (
          <div
            className="cursor-pointer w-7 h-7 px-3 rounded hover:bg-gray-f2 text-blue-primary text-lg"
            onClick={(event: React.MouseEvent) => {
              event.stopPropagation();
              const rect = (
                event.currentTarget as HTMLDivElement
              ).getBoundingClientRect();
              setActionMenuPosition({
                top: rect.bottom + window.scrollY,
                left: rect.left + window.scrollX,
              });
              setActiveRow((prev) =>
                prev?.id === row.original.id
                  ? null
                  : { id: row.original.id, status: row.original.status }
              );
            }}
          >
            <FontAwesomeIcon icon={faEllipsisV} />
          </div>
        ),
      },
    ],
    [responseData, sortConfig]
  );

  const actionModalConfig = {
    publish: {
      title: "publish",
      message: "publish_testimonial_message",
      confirmButtonLabel: "Publish",
    },
    draft: {
      title: "move_to_draft",
      message: "draft_testimonial_message",
      confirmButtonLabel: "move_to_draft",
    },
    delete: {
      title: "delete",
      message: "delete_testimonial_message",
      confirmButtonLabel: "delete",
    },
  };

  const updateStatusHandler = async (_id: number, newStatus: string) => {
    updateStatus(
      async (response: Response) => {
        if (response.ok) {
          setSnackbar({
            message:
              newStatus === "PS"
                ? "Testimonial published successfully"
                : "Testimonial moved to draft successfully",
            type: SnackBarType.EDIT,
            show: true,
          });
          setResponseData((prevData) =>
            prevData.map((testimonial) =>
              testimonial.id === _id
                ? { ...testimonial, status: newStatus }
                : testimonial
            )
          );
        }
      },
      _id,
      newStatus
    );
  };

  const deleteHandler = (_id: number) => {
    deleteTestimonial((response: Response) => {
      if (response.ok) {
        setSnackbar({
          message: t("testimonial_deleted_successfully"),
          type: SnackBarType.EXPORT,
          show: true,
        });
        getTestimonialsList();
      }
    }, _id);
    setShowActionModal(false);
    setSelectedTestimonial(null);
  };

  return (
    <MainContainer>
      <Snackbar
        open={snackbar.show}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        sx={{
          "& .MuiSnackbarContent-root": { backgroundColor: "#03A678" },
          "& .MuiSnackbarContent-message": {
            fontSize: "16px",
            fontFamily: "'Open Sans', sans-serif",
            fontWeight: "100",
          },
        }}
        onClose={() => {
          setSnackbar({
            message: "",
            type: 0,
            show: false,
          });
        }}
      >
        <SnackbarContent message={<p>{snackbar.message}</p>} />
      </Snackbar>

      <main className="alignment mt-4">
        <header className="flex flex-row justify-between items-center flex-wrap gap-x-32 gap-y-2">
          <h3 className="text-lg font-semibold text-gray-27 my-auto">
            {t("testimonial_list")}
          </h3>
          <Button
            onClick={() => navigate(`/testimonials/testimonial`)}
            type={ButtonTypes.PRIMARY}
            className="max-w-[174px] !px-5"
          >
            <FontAwesomeIcon icon={faPlus} className="mr-2.5" size="lg" />
            {t("add_testimonial")}
          </Button>
        </header>
        <div className="bg-white rounded shadow px-6 mt-4">
          <SearchSection
            onSearch={(_searchInput) => {
              setSearchInput(_searchInput);
            }}
            hasFilter={false}
          />
        </div>

        <div className="relative bg-white rounded shadow px-4 mt-[6px] pb-6">
          <div className="mb-3 overflow-x-auto z-10 min-h-[400px]">
            {showLoading ? (
              <div className="flex w-full h-[500px] items-center justify-center">
                <CircularProgress size={64} />
              </div>
            ) : responseData?.length > 0 ? (
              <div className="relative">
                <Table<TestimonialType> columns={columns} data={responseData} />

                {activeRow &&
                  actionMenuPosition &&
                  createPortal(
                    <div
                      ref={actionMenuRef}
                      className="absolute w-32 bg-white rounded-md shadow-lg z-50 text-gray-50"
                      style={{
                        top: actionMenuPosition.top + 4,
                        left: actionMenuPosition.left - 100,
                      }}
                      onMouseDown={(e) => e.stopPropagation()}
                    >
                      <ul className="text-sm text-gray-50">
                        <li
                          className="px-4 py-2.5 hover:bg-gray-100 cursor-pointer"
                          onClick={() => {
                            setSelectedTestimonial(activeRow.id);
                            setShowPreview(true);
                            setActiveRow(null);
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faEye}
                            className="mr-1.5 text-gray-41"
                          />
                          {t("preview")}
                        </li>
                        <li
                          className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                          onClick={() => {
                            setSelectedTestimonial(activeRow.id);
                            setModalAction(
                              activeRow.status === "PS" ? "draft" : "publish"
                            );
                            setShowActionModal(true);
                            setActiveRow(null);
                          }}
                        >
                          <FontAwesomeIcon
                            icon={
                              activeRow.status === "DF" ? faUpload : faFileAlt
                            }
                            className="mr-1.5 text-gray-41"
                          />
                          {activeRow.status === "DF"
                            ? t("publish")
                            : t("draft")}
                        </li>
                        <li
                          className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                          onClick={() => {
                            navigate(`/testimonials/testimonial`, {
                              state: {
                                testimonial: responseData.find(
                                  (data) => data.id === activeRow.id
                                ),
                              },
                            });
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faPen}
                            className="mr-1.5 text-gray-41"
                          />
                          {t("edit")}
                        </li>
                        <li
                          className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                          onClick={() => {
                            setSelectedTestimonial(activeRow.id);
                            setModalAction("delete");
                            setShowActionModal(true);
                            setActiveRow(null);
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faTrash}
                            className="mr-1.5 text-gray-41"
                          />
                          {t("delete")}
                        </li>
                      </ul>
                    </div>,
                    document.body
                  )}
              </div>
            ) : (
              <NoResultMessage />
            )}
          </div>

          {!showLoading && responseData?.length > 0 && (
            <Pagination
              dropdownKeys={["5", "10", "20"]}
              pageIndex={pageIndex}
              pageSize={pageSize}
              dataCount={dataCount}
              onPageChange={setPageIndex}
              onPageSizeChange={(newSize) => {
                setPageSize(newSize);
                setPageIndex(0);
              }}
            />
          )}
        </div>

        {showPreview && (
          <TestimonialPreview
            testimonial={
              responseData.find((data) => data.id === selectedTestimonial)!
            }
            onEdit={() => {
              navigate(`/testimonials/testimonial`, {
                state: {
                  testimonial: responseData.find(
                    (data) => data.id === selectedTestimonial
                  ),
                },
              });
            }}
            onBackToTable={() => setShowPreview(false)}
            onBackdrop={() => setShowPreview(false)}
          />
        )}

        {showActionModal && modalAction && (
          <LastConfirmWarningModal
            title={actionModalConfig[modalAction].title}
            message={actionModalConfig[modalAction].message}
            confirmButtonLabel={actionModalConfig[modalAction].confirmButtonLabel}
            onCancel={() => setShowActionModal(false)}
            onConfirm={() => {
              if (selectedTestimonial !== null) {
                if (modalAction === "publish" || modalAction === "draft") {
                  updateStatusHandler(
                    selectedTestimonial,
                    modalAction === "publish" ? "PS" : "DF"
                  );
                } else if (modalAction === "delete") {
                  deleteHandler(selectedTestimonial);
                }
                setShowActionModal(false);
              }
            }}
            onBackdrop={() => setShowActionModal(false)}
          />
        )}
      </main>
    </MainContainer>
  );
};

export default Testimonial;
