import React from "react";
import { useTranslation } from "react-i18next";
import Button from "../../UI/Button";
import { ButtonTypes, StatusBadgeTypes } from "../../../utils/Constants";
import Testimonial, { MultilingualField } from "../../../types/Testimonial";
import StatusBadge from "../../UI/StatusBadge";
import i18next from "i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faChevronLeft,
  faQuoteLeft,
} from "@fortawesome/free-solid-svg-icons";

const TestimonialPreview: React.FC<{
  testimonial: Testimonial;
  onEdit: () => void;
  onBackToTable: () => void;
  onBackdrop: () => void;
}> = ({ testimonial, onEdit, onBackToTable, onBackdrop }) => {
  const { t } = useTranslation();

  const getTranslation = (field: MultilingualField) => {
    return field[i18next.language] || Object.values(field)[0];
  };

  return (
    <div className="backdrop overflow-y-auto" onClick={onBackdrop}>
      <div
        className="bg-white w-[85%] max-w-6xl h-[90%] overflow-y-auto p-6 rounded-lg shadow-lg"
        onClick={(e) => e.stopPropagation()}
      >
        <h3 className="text-black41 text-lg font-semibold">
          {t("blog_preview")}
        </h3>

        <div className="bg-gray-f6 px-6 py-[18px] flex flex-row justify-between rounded my-6 flex-wrap gap-x-6 gap-y-2">
          <p className="text-[#241E5C] font-bold my-auto">{getTranslation(testimonial.full_name)}</p>
          <StatusBadge type={testimonial.status === "DF" ? StatusBadgeTypes.DRAFT : StatusBadgeTypes.PUBLISHED} />
        </div>

        <section className="flex flex-col w-full p-6 border-4 border-gray-e8">
          <div className="bg-[#241E5C] pt-7 px-[6%] rounded-t h-60">
            <h2 className="500:text-2xl text-lg font-bold text-white w-full flex md:justify-start justify-center text-center">
              {t("insights_and_experiences")}
            </h2>
          </div>

          <div className="text-[#241E5C] bg-white shadow-lg w-[85%] rounded-[20px] mx-auto py-6 500:-mt-40 -mt-36">
            <div className="flex flex-col">
              <div className="relative w-full h-32">
                <div className="flex justify-center w-full h-32 space-x-4 overflow-x-hidden">
                  {[...Array(4), "center", ...Array(4)].map((item, index) => (
                    <div
                      key={index}
                      className={`flex-shrink-0 transition-all duration-700 ${item === "center"
                          ? "w-32 h-32"
                          : "w-16 h-16 bg-gray-e8 bg-gradient-to-t from-transparent to-white my-auto"
                        } rounded-2xl flex items-center justify-center overflow-hidden`}
                    >
                      {item === "center" && (
                        <img
                          src={`${process.env.REACT_APP_BASE_URL_API?.replace(
                            "api/",
                            ""
                          )}${testimonial.image}`}
                          alt={getTranslation(testimonial.full_name)}
                          className="w-full h-full object-cover rounded-2xl"
                        />
                      )}
                    </div>
                  ))}
                </div>
                <div className="flex absolute inset-y-0 left-0 w-[25%] h-full bg-gradient-to-r from-white to-transparent pointer-events-none"></div>
                <div className="flex absolute inset-y-0 right-0 w-[25%] h-full bg-gradient-to-l from-white to-transparent pointer-events-none"></div>
              </div>

              <div className="flex flex-col w-full h-full text-[#241E5C] mt-4 mb-3.5 text-[10px] px-4">
                <div className="flex flex-col items-center text-center">
                  <label className="font-semibold">
                    {getTranslation(testimonial.full_name)}
                  </label>
                  <label className="whitespace-pre-wrap">
                    {testimonial.role && getTranslation(testimonial.role)}
                  </label>
                  <label>
                    {testimonial.country_name}
                  </label>
                </div>

                <div className="mt-1">
                  <div className="flex w-full items-center">
                    <FontAwesomeIcon icon={faQuoteLeft} className="text-base" />
                  </div>
                  <p
                    className="w-full mt-4 whitespace-pre-line"
                    dangerouslySetInnerHTML={{
                      __html: getTranslation(testimonial.experience)
                        .replace(/\\n/g, '\n')
                        .replace(/(https?:\/\/[^\s]+)/g, (url) => `<a href="${url}" class="text-blue-500" target="_blank" rel="noopener noreferrer">${url}</a>`),
                    }}
                  ></p>
                </div>
              </div>
              <div className="flex justify-center gap-6">
                <div className="w-5 h-5 px-1.5 py-0.5 rounded bg-[#f3f3f3] text-[#241E5C] text-xs">
                  <FontAwesomeIcon icon={faChevronLeft} />
                </div>
                <div className="w-5 h-5 px-1.5 py-0.5 rounded bg-[#f3f3f3] text-[#241E5C] text-xs">
                  <FontAwesomeIcon icon={faChevronRight} />
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="flex 360:flex-row gap-x-4 justify-end flex-col flex-wrap gap-y-2  items-center mt-10">
          <Button type={ButtonTypes.SECONDARY} onClick={onEdit}>
            {t("edit")}
          </Button>
          <Button type={ButtonTypes.PRIMARY} onClick={onBackToTable}>
            {t("back_to_table")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default TestimonialPreview;
