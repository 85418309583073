import React from "react";

const LanguageBox: React.FC<{
  onChange: (lng: string) => void;
  selectedLanguage: string;
}> = ({ onChange, selectedLanguage }) => {

  return (
    <div
      className="flex top-0 right-0 rounded text-sm h-10"
    >
      <div
        onClick={() => {
          onChange("en");
        }}
        className={`font-bold py-2.5 px-5 hover:cursor-pointer ${selectedLanguage === "en"
            ? "bg-[#007BB0] text-white"
            : "bg-[#F4F4F4] text-[#007BB0]"
          } rounded-l duration-300`}
      >
        EN
      </div>

      <div
        onClick={() => {
          onChange("fr");
        }}
        className={`font-bold py-2.5 px-5 hover:cursor-pointer ${selectedLanguage === "fr"
            ? "bg-[#007BB0] text-white"
            : "bg-[#F4F4F4] text-[#007BB0]"
          } rounded-r duration-300`}
      >
        FR
      </div>
    </div>
  );
};

export default LanguageBox;
