import { useEffect, useMemo, useRef, useState } from "react";
import { CircularProgress, Snackbar, SnackbarContent } from "@mui/material";
import { Column, Row } from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faTrash } from "@fortawesome/pro-light-svg-icons";
import { faEllipsisV } from "@fortawesome/pro-regular-svg-icons";
import { useTranslation } from "react-i18next";
import MainContainer from "../../UI/MainContainer";
import Moment from "react-moment";
import NoResultMessage from "../../UI/NoResultMessage";
import TrainingCandidate from "../../../types/TrainingCandidate";
import TrainingCandidatesApi from "../../../store/api/TrainingCandidatesApi";
import saveAs from "file-saver";
import { getDate } from "../../../utils/getDate";
import { getValueFromTupleList, getValueFromObjectList } from "../../../utils/getValue";
import Pagination from "../../UI/Pagination";
import { ButtonTypes, SnackBarType, StatusBadgeTypes } from "../../../utils/Constants";
import StatusBadge from "../../UI/StatusBadge";
import SearchSection from "../../UI/SearchSection";
import FilterSection, { FilterState } from "./TrainingCandidatesFilterSection";
import Button from "../../UI/Button";
import { useNavigate } from "react-router-dom";
import LastConfirmWarningModal from "../../UI/LastConfirmWarningModal";
import LoadingSpinner from "../../UI/LoadingSpinner";
import { createPortal } from "react-dom";
import { renderSortableHeader, SortConfig } from "../../../utils/renderSortableHeader";
import { toggleSelectAll } from "../../../utils/toggleSelectAll";
import { toggleRowSelection } from "../../../utils/toggleRowSelection";
import Table from "../../UI/Table";

export type SnackbarType = {
  message: string;
  type: number;
  show: boolean;
};

// Training candidate list local storage page index
const TC_LIST_LS_PI = "TC_LIST_LS_PI";

const TrainingCandidates: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showLoading, setShowLoading] = useState(false);
  const [showLoadingSpinner, setShowLoadingSpinner] = useState(false);
  const [searchInput, setSearchInput] = useState<string>("");
  const [showFilterSection, setShowFilterSection] = useState(false);
  const [responseData, setResponseData] = useState<TrainingCandidate[]>([]);
  const [referralList, setReferralList] = useState<[string, string][]>([]);
  const [organizationList, setOrganizationList] = useState<[string, string][]>([]);
  const [filterState, setFilterState] = useState<FilterState>({
    referral: "AL",
    status: "AL",
    dateFrom: null,
    dateTo: null,
  });
  const [snackbar, setSnackbar] = useState<SnackbarType>({
    message: "",
    type: 0,
    show: false,
  });
  const [selectedRowIds, setSelectedRowIds] = useState<number[]>([]);
  const [pageIndex, setPageIndex] = useState<number>(Number(localStorage.getItem(TC_LIST_LS_PI) ?? 0));
  const [pageSize, setPageSize] = useState<number>(10);
  const [dataCount, setDataCount] = useState<number>(0);
  const [sortConfig, setSortConfig] = useState<SortConfig | undefined>(undefined);
  const [activeRowId, setActiveRowId] = useState<number | null>(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [trainingCandidateToDelete, setTrainingCandidateToDelete] = useState<number | null>(null);
  const [dropdownPosition, setDropdownPosition] = useState<{
    top: number;
    left: number;
  } | null>(null);
  const { getTrainingCandidates, deleteTrainingCandidate, exportTrainingCandidates } = TrainingCandidatesApi();
  const actionMenuRef = useRef<HTMLDivElement | null>(null);

  const getTrainingCandidatesList = () => {
    setShowLoading(true);
    getTrainingCandidates(`${"training-candidate/"}${constructQueryParams()}`, async (response: Response) => {
      const result = await response.json();
      if (!response.ok) {
        return;
      }

      const processedTrainees = result.trainees.map((trainee: any) => ({
        ...trainee,
        referral: getValueFromTupleList(trainee.referral, [...result.referrals, ...result.socials]),
        clinic: getValueFromObjectList(trainee.clinic, result.organizations),
      }));

      setResponseData(processedTrainees);
      setReferralList([...result.referrals, ...result.socials]);
      setOrganizationList(result.organizations);
      setDataCount(result.total_count);
      setShowLoading(false);
    });
  };

  const constructQueryParams = (includePagination: boolean = true) => {
    let queryParams: string[] = [];
    if (includePagination) queryParams.push(`page_number=${pageIndex + 1}&page_size=${pageSize}`);
    if (searchInput) {
      queryParams.push(`search_trainee=${searchInput}`);
    }
    if (filterState.referral !== "AL") {
      queryParams.push(`trainee_referral=${filterState.referral}`);
    }
    if (filterState.status !== "AL") {
      queryParams.push(`trainee_status=${filterState.status}`);
    }
    if (filterState.dateFrom !== null) {
      queryParams.push(`trainee_from=${getDate(filterState.dateFrom.toString(), false)}`);
    }
    if (filterState.dateTo !== null) {
      queryParams.push(`trainee_to=${getDate(filterState.dateTo.toString(), false)}`);
    }
    if (sortConfig) {
      queryParams.push(`sort_by=${sortConfig.column}&sort_type=${sortConfig.sortType}`);
    }
    return `?${queryParams.join("&")}`;
  };

  const goToFirstPage = () => {
    setPageIndex(0);
  };

  const savePageIndexInLocalStorage = (newPageIndex: number) => {
    localStorage.setItem(TC_LIST_LS_PI, newPageIndex.toString());
  };

  useEffect(() => {
    getTrainingCandidatesList();
  }, [pageIndex, pageSize, searchInput, filterState, sortConfig]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (actionMenuRef.current && !actionMenuRef.current.contains(event.target as Node)) setActiveRowId(null);
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const columns: Column<TrainingCandidate>[] = useMemo(
    () => [
      {
        Header: (props) => (
          <div className="flex items-center">
            <input
              type="checkbox"
              onChange={() => toggleSelectAll(responseData, selectedRowIds, setSelectedRowIds, (trainee) => trainee.id)}
              checked={responseData.length > 0 && responseData.every((row) => selectedRowIds.includes(row.id))}
              className="table__checkbox mr-4"
            />
            {renderSortableHeader(props.column, t("full_name"), sortConfig, setSortConfig)}
          </div>
        ),
        accessor: (row) => `${row.first_name} ${row.last_name}`,
        id: "full_name",
        sortType: (rowA, rowB) => {
          const fullNameA = `${rowA.original.first_name} ${rowA.original.last_name}`.toLowerCase();
          const fullNameB = `${rowB.original.first_name} ${rowB.original.last_name}`.toLowerCase();
          return fullNameA.localeCompare(fullNameB);
        },
        Cell: ({ row }: { row: Row<TrainingCandidate> }) => (
          <div className="flex items-center">
            <input
              type="checkbox"
              checked={selectedRowIds.includes(row.original.id)}
              onChange={() => toggleRowSelection(row.original.id, setSelectedRowIds)}
              className="table__checkbox mr-4"
            />
            <span>{`${row.original.first_name} ${row.original.last_name}`}</span>
          </div>
        ),
      },
      {
        Header: (props) => renderSortableHeader(props.column, t("email"), sortConfig, setSortConfig),
        accessor: "email",
      },
      {
        Header: (props) => renderSortableHeader(props.column, t("phone"), sortConfig, setSortConfig),
        accessor: "phone",
      },
      {
        Header: (props) => renderSortableHeader(props.column, t("clinic"), sortConfig, setSortConfig),
        accessor: "clinic",
      },
      {
        Header: (props) => renderSortableHeader(props.column, t("referral_source"), sortConfig, setSortConfig),
        accessor: "referral",
      },
      {
        Header: (props) => renderSortableHeader(props.column, t("contact_date"), sortConfig, setSortConfig),
        accessor: "contact_date",
        Cell: ({ value }) => <Moment format="DD/MM/YYYY">{value}</Moment>,
      },
      {
        Header: (props) => renderSortableHeader(props.column, t("status"), sortConfig, setSortConfig),
        accessor: "status",
        Cell: ({ value }: { value: string }) => {
          let statusType: StatusBadgeTypes | undefined;
          switch (value) {
            case "RS":
              statusType = StatusBadgeTypes.RESPONDED;
              break;
            case "PD":
              statusType = StatusBadgeTypes.PENDING;
              break;
            default:
              statusType = undefined;
          }
          return statusType ? <StatusBadge type={statusType} /> : <span>{t("unknown_status")}</span>;
        },
      },
      {
        Header: "",
        id: "actions",
        Cell: ({ row }: { row: any }) => (
          <div
            className="cursor-pointer w-7 h-7 px-3 rounded hover:bg-gray-f2 text-blue-primary text-lg"
            onClick={(event: React.MouseEvent) => {
              event.stopPropagation();
              const rect = (event.currentTarget as HTMLDivElement).getBoundingClientRect();
              setDropdownPosition({
                top: rect.bottom + window.scrollY,
                left: rect.left + window.scrollX,
              });
              setActiveRowId((prevId) => (prevId === row.original.id ? null : row.original.id));
            }}
          >
            <FontAwesomeIcon icon={faEllipsisV} />
          </div>
        ),
      },
    ],
    [t, selectedRowIds, responseData, sortConfig]
  );

  const deleteHandler = (_id: number) => {
    deleteTrainingCandidate((response: Response) => {
      if (response.ok) {
        setSnackbar({
          message: t("record_deleted_successfully"),
          type: SnackBarType.EXPORT,
          show: true,
        });
        getTrainingCandidatesList();
      }
    }, _id);
    setShowDeleteModal(false);
    setTrainingCandidateToDelete(null);
  };

  const exportHandler = () => {
    setShowLoadingSpinner(true);
    exportTrainingCandidates(
      constructQueryParams(false) +
        (selectedRowIds.length > 0
          ? `&selected_items=${selectedRowIds.map((selectedItem) => selectedItem).join(",")}`
          : ""),
      async (response: Response) => {
        if (response.ok) {
          const blob = await response.blob();
          saveAs(blob, `"training_candidates".csv`);
          setSnackbar({
            message: t("export_completely_message"),
            type: SnackBarType.EXPORT,
            show: true,
          });
          setShowLoadingSpinner(false);
        } else {
          setShowLoadingSpinner(false);
        }
      }
    );
  };

  return (
    <MainContainer>
      <Snackbar
        open={snackbar.show}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        sx={{
          "& .MuiSnackbarContent-root": { backgroundColor: "#03A678" },
          "& .MuiSnackbarContent-message": {
            fontSize: "16px",
            fontFamily: "'Open Sans', sans-serif",
            fontWeight: "100",
          },
        }}
        onClose={() => {
          setSnackbar({
            message: "",
            type: 0,
            show: false,
          });
        }}
      >
        <SnackbarContent message={<p>{snackbar.message}</p>} />
      </Snackbar>

      {showLoadingSpinner && (
        <LoadingSpinner titleKey={t("export_to_csv")} onBackdrop={() => setShowLoadingSpinner(false)} />
      )}

      <main className="alignment mt-4">
        <header className="flex flex-row justify-between items-center flex-wrap gap-x-32 gap-y-2">
          <h3 className="text-lg font-semibold text-gray-27 my-auto">{t("training_candidates")}</h3>
          {responseData.length > 0 && !showLoading && (
            <Button onClick={exportHandler} type={ButtonTypes.PRIMARY} className="max-w-[155px]">
              {t("export_to_csv")}
            </Button>
          )}
        </header>

        <div className="bg-white rounded shadow px-6 mt-4">
          <SearchSection
            onSearch={(_searchInput) => {
              setSearchInput(_searchInput);
              goToFirstPage();
            }}
            onSliderClicked={() => {
              setShowFilterSection((preState) => !preState);
            }}
            isFilterMode={showFilterSection}
          />
          {showFilterSection && (
            <FilterSection
              referralList={referralList}
              onApply={(appliedFilterState: FilterState) => {
                setFilterState(appliedFilterState);
                goToFirstPage();
              }}
              onReset={() => {
                setFilterState({
                  referral: "AL",
                  status: "AL",
                  dateFrom: null,
                  dateTo: null,
                });
                goToFirstPage();
              }}
              initialFilterState={filterState}
            />
          )}
        </div>

        <div className="relative bg-white rounded shadow px-4 mt-[6px] pb-6">
          <div className="mb-10 overflow-x-auto z-10 min-h-[400px]">
            {showLoading ? (
              <div className="flex w-full h-[500px] items-center justify-center">
                <CircularProgress size={64} />
              </div>
            ) : responseData.length > 0 ? (
              <div className="relative">
                <Table<TrainingCandidate> columns={columns} data={responseData} />
                {activeRowId !== null &&
                  dropdownPosition &&
                  createPortal(
                    <div
                      ref={actionMenuRef}
                      className="absolute w-32 bg-white rounded-md shadow-lg z-50 text-gray-50"
                      style={{
                        top: dropdownPosition.top + 4,
                        left: dropdownPosition.left - 100,
                      }}
                      onMouseDown={(e) => e.stopPropagation()}
                    >
                      <ul className="text-sm text-gray-50">
                        <li
                          className="px-4 py-2.5 hover:bg-gray-100 cursor-pointer"
                          onClick={() => {
                            navigate(`/training-candidate/${activeRowId}`, {
                              state: {
                                referralList,
                                organizationList,
                              },
                            });
                            setActiveRowId(null);
                          }}
                        >
                          <FontAwesomeIcon icon={faEye} className="mr-1.5 text-gray-41" />
                          {t("preview")}
                        </li>
                        <li
                          className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                          onClick={() => {
                            setTrainingCandidateToDelete(activeRowId);
                            setShowDeleteModal(true);
                            setActiveRowId(null);
                          }}
                        >
                          <FontAwesomeIcon icon={faTrash} className="mr-1.5 text-gray-41" />
                          {t("delete")}
                        </li>
                      </ul>
                    </div>,
                    document.body
                  )}
              </div>
            ) : (
              <NoResultMessage />
            )}
          </div>

          {!showLoading && responseData.length > 0 && (
            <Pagination
              dropdownKeys={["5", "10", "20"]}
              pageIndex={pageIndex}
              pageSize={pageSize}
              dataCount={dataCount}
              onPageChange={(pageIndex) => {
                setPageIndex(pageIndex);
                savePageIndexInLocalStorage(pageIndex);
              }}
              onPageSizeChange={(newSize) => {
                setPageSize(newSize);
                goToFirstPage();
                savePageIndexInLocalStorage(pageIndex);
              }}
            />
          )}
        </div>

        {showDeleteModal && (
          <LastConfirmWarningModal
            title="delete"
            message="delete_record_message"
            confirmButtonLabel="yes_delete"
            onCancel={() => setShowDeleteModal(false)}
            onConfirm={() => {
              if (trainingCandidateToDelete !== null) deleteHandler(trainingCandidateToDelete);
            }}
            onBackdrop={() => setShowDeleteModal(false)}
          />
        )}
      </main>
    </MainContainer>
  );
};

export default TrainingCandidates;
